:root {
  --popup-background-color: #7fa972;
  /* --rsbs-bg: url("assets/images/sidebar.png"); */
  --rsbs-bg: #f9f8f3;
  --loader: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
}

.stripes-background {
  background-image: repeating-linear-gradient(
      130deg,
      rgba(208, 208, 208, 0.13) 0px,
      rgba(208, 208, 208, 0.13) 43px,
      rgba(195, 195, 195, 0.13) 43px,
      rgba(195, 195, 195, 0.13) 85px,
      rgba(41, 41, 41, 0.13) 85px,
      rgba(41, 41, 41, 0.13) 109px,
      rgba(88, 88, 88, 0.13) 109px,
      rgba(88, 88, 88, 0.13) 129px,
      rgba(24, 24, 24, 0.13) 129px,
      rgba(24, 24, 24, 0.13) 139px,
      rgba(92, 92, 92, 0.13) 139px,
      rgba(92, 92, 92, 0.13) 179px,
      rgba(37, 37, 37, 0.13) 179px,
      rgba(37, 37, 37, 0.13) 219px
    ),
    repeating-linear-gradient(
      130deg,
      rgba(18, 18, 18, 0.13) 0px,
      rgba(18, 18, 18, 0.13) 13px,
      rgba(48, 48, 48, 0.13) 13px,
      rgba(48, 48, 48, 0.13) 61px,
      rgba(130, 130, 130, 0.13) 61px,
      rgba(130, 130, 130, 0.13) 84px,
      rgba(233, 233, 233, 0.13) 84px,
      rgba(233, 233, 233, 0.13) 109px,
      rgba(8, 8, 8, 0.13) 109px,
      rgba(8, 8, 8, 0.13) 143px,
      rgba(248, 248, 248, 0.13) 143px,
      rgba(248, 248, 248, 0.13) 173px,
      rgba(37, 37, 37, 0.13) 173px,
      rgba(37, 37, 37, 0.13) 188px
    ),
    repeating-linear-gradient(
      130deg,
      rgba(3, 3, 3, 0.1) 0px,
      rgba(3, 3, 3, 0.1) 134px,
      rgba(82, 82, 82, 0.1) 134px,
      rgba(82, 82, 82, 0.1) 282px,
      rgba(220, 220, 220, 0.1) 282px,
      rgba(220, 220, 220, 0.1) 389px,
      rgba(173, 173, 173, 0.1) 389px,
      rgba(173, 173, 173, 0.1) 458px,
      rgba(109, 109, 109, 0.1) 458px,
      rgba(109, 109, 109, 0.1) 516px,
      rgba(240, 240, 240, 0.1) 516px,
      rgba(240, 240, 240, 0.1) 656px,
      rgba(205, 205, 205, 0.1) 656px,
      rgba(205, 205, 205, 0.1) 722px
    ),
    linear-gradient(90deg, rgb(9, 106, 9), rgb(8, 189, 47));
}

.scrollbar-gutter {
  scrollbar-gutter: stable both-edges;
}

.volume-thumb::-webkit-slider-thumb {
  @apply h-5 w-5 cursor-pointer appearance-none rounded-full bg-primary;
}
.volume-thumb::-moz-range-thumb {
  @apply h-5 w-5 cursor-pointer appearance-none rounded-full bg-primary;
}

.mapboxgl-ctrl-bottom-left {
  position: absolute !important;
  z-index: 40 !important;
}

.mapboxgl-ctrl-top-right {
  @apply hidden sm:right-[5px] sm:top-[100px] sm:block lg:top-[60px] !important;
}

.mapboxgl-ctrl-bottom-right {
  position: absolute !important;
  bottom: 2.5rem !important;
  display: none !important;
}

@media (min-width: 1024px) {
  .mapboxgl-ctrl-bottom-left {
    left: 3.5rem !important;
  }
  .mapboxgl-ctrl-bottom-right {
    display: block !important;
  }
}

.mapboxgl-ctrl-zoom-in {
  @apply bg-mediumGreen text-darkCharcoal hover:bg-green5 !important;
}
.mapboxgl-ctrl-zoom-out {
  @apply bg-mediumGreen text-darkCharcoal hover:bg-green5 !important;
}
.mapboxgl-ctrl-compass {
  @apply bg-mediumGreen text-darkCharcoal hover:bg-green5 !important;
}

.mapboxgl-ctrl-group button + button {
  @apply border-t border-solid border-darkCharcoal !important;
}

.mapboxgl-ctrl-geocoder {
  @apply min-w-full rounded-md py-[1px] font-plusJakarta text-xs leading-4 !important;
}

.mapboxgl-ctrl-geocoder--input {
  @apply h-9 px-8 py-1.5 focus:outline-none !important;
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  @apply brightness-0 invert;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address,
.mapboxgl-ctrl-geocoder--powered-by {
  font-size: 12px !important;
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 7px !important;
  left: 6px !important;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  top: 0px !important;
  right: 5px !important;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 3px !important;
  margin-right: 1px !important;
  height: 15px !important;
  width: 15px !important;
}

.mapboxgl-popup-close-button {
  top: 2px !important;
  right: 4px !important;
  font-size: 18px;
}

.mapboxgl-popup {
  max-width: 450px !important;
  z-index: 30;
}

.mapboxgl-popup-content {
  background: var(--popup-background-color) !important;
  color: white;
  border-radius: 12px !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: var(--popup-background-color) !important;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--popup-background-color) !important;
}

.progressbar-container {
  @apply !top-auto bottom-2 right-0 mx-auto !flex !h-2 !w-[90%] !self-center lg:bottom-4 lg:!h-4;
}

.shield-marker {
  @apply flex w-10 cursor-pointer;
}

.shield-marker img {
  @apply h-full w-full object-contain;
}

.custom-marker {
  @apply flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-[3px] transition duration-300 ease-in-out after:absolute after:left-1/2 after:top-[95%] after:z-10 after:h-0 after:w-0 after:-translate-x-1/2 after:border-l-[6px] after:border-r-[6px] after:border-t-[6px] after:border-solid after:border-transparent after:border-t-white hover:scale-125;
}

.custom-marker img {
  @apply h-full w-full rounded-full object-cover;
}

.mapboxgl-ctrl-geocoder {
  @apply text-sm !important;
}

/* stepper start */
.step-container {
  @apply relative flex w-30 flex-col items-center justify-center;
}
.step-container:not(:first-child):before {
  @apply absolute right-2/4 top-1/2 h-[3px] w-full bg-slate-200 content-[''];
}
.step {
  @apply relative z-10 flex h-6 w-6 items-center justify-center rounded-full bg-yellow-300 font-semibold text-darkCharcoal transition duration-500 ease-in-out hover:bg-yellow-400;
}
.active-step .step {
  @apply scale-[2] bg-sky-600 text-white;
}
.complete .step {
  @apply bg-green-600 text-white;
}

.complete:not(:first-child):before,
.active-step:not(:first-child):before {
  @apply bg-green-600;
}
/* stepper end */

/* button:disabled,
button[disabled],
.disabled {
  background-color: #adadad !important;
  border-color: #adadad !important;
  color: white !important;
  pointer-events: none;
  cursor: default;
} */

.user-tokens::-webkit-scrollbar {
  width: 0.5rem;
}
.user-tokens::-webkit-scrollbar-track {
  background-color: transparent;
}
.user-tokens::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 0.5rem;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.user-tokens::-webkit-scrollbar-thumb:hover {
  background-color: #c0c0c0;
}

.rtl-grid {
  direction: rtl;
}

.loader {
  -webkit-mask: var(--loader);
  mask: var(--loader);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
